import { UserRetrievableState } from "@improbable/cloudflare-user-auth";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import * as React from "react";

import { loginRoute, logoutRoute } from "./common";

export function ProfileArea(props: { user: UserRetrievableState | null }) {
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
    null
  );
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };
  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  if (props.user) {
    let name = `User#${props.user.id}`;
    if (props.user.givenName) {
      name = props.user.givenName;
    } else if (props.user.name) {
      name = props.user.name;
    }
    return (
      <>
        <Tooltip title="Open settings">
          <IconButton onClick={handleOpenUserMenu}>
            <Avatar
              alt={name}
              referrerPolicy={"no-referrer"}
              src={props.user.picture}
            />
          </IconButton>
        </Tooltip>
        <Menu
          sx={{ mt: "45px" }}
          id="menu-appbar"
          anchorEl={anchorElUser}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          keepMounted
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={Boolean(anchorElUser)}
          onClose={handleCloseUserMenu}
        >
          <a href={logoutRoute}>
            <MenuItem key={"signout"} onClick={handleCloseUserMenu}>
              <Typography textAlign="center">Sign Out</Typography>
            </MenuItem>
          </a>
        </Menu>
      </>
    );
  }

  return (
    <div>
      <a href={loginRoute}>Sign In</a>
    </div>
  );
}
