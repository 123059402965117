
const digest = 'ab2169e2464af3670e48772dd0d4991d415255e992ad47e66c64d273016f6e9a';
const css = `._mml-panel_1uycy_1 {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding: 8px;
}

._main-row_1uycy_8 {
    display: flex;
    flex-direction: row;
}

._position-area_1uycy_13 {
    display: flex;
    align-items: center;
    margin-top: 8px;
}

._position-label_1uycy_19 {
    display: flex;
    padding: 10px;
}

._position-inputs_1uycy_24 {
    display: flex;
    flex-grow: 1;
}

._position-input_1uycy_24 {
    display: flex;
    flex-grow: 1;
    margin-left: 8px;
}`;

(function() {
  if (typeof document === 'undefined') {
    return;
  }
  if (!document.getElementById(digest)) {
    var el = document.createElement('style');
    el.id = digest;
    el.textContent = css;
    document.head.appendChild(el);
  }
})();
    
export default {"mmlPanel":"_mml-panel_1uycy_1","mainRow":"_main-row_1uycy_8","positionArea":"_position-area_1uycy_13","positionLabel":"_position-label_1uycy_19","positionInputs":"_position-inputs_1uycy_24","positionInput":"_position-input_1uycy_24"};
export { css, digest };
  