import { jsonApiRequest } from "@meta-web/configuration-durable-object";
import { Button } from "@mui/material";
import * as React from "react";

import { devDelay } from "../dev-delay";

export function NewSpaceButton() {
  const [isCreating, setIsCreating] = React.useState(false);

  const handleClick = () => {
    setIsCreating(true);

    devDelay(() => {
      jsonApiRequest<{ id: string }>("POST", `/api/v1/spaces`, {})
        .then((json) => {
          console.log("json", json);
          window.location.href = `space/${json.id}`;
        })
        .catch((e) => {
          console.error("error creating space", e);
        });
    });
  };

  return (
    <div>
      <Button variant="contained" disabled={isCreating} onClick={handleClick}>
        Create New Space
        {isCreating && " ...Creating..."}
      </Button>
    </div>
  );
}
