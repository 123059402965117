
const digest = '587f0e711686ed1abfc0df0068877e24d5f1968b58fdbfa81d6b099a5528c066';
const css = `._script-entry_b9gr7_1 {
    display: flex;
    flex-direction: column;
    background-color: #F3F8FB;
    border: 1px solid #D7D8E7;
    margin-bottom: 8px;
    color: #515A64;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    border-radius: 3px;
}

._title-row_b9gr7_12 {
    display: flex;
    padding: 8px;
    flex-direction: row;
    align-items: center;
    border-bottom: 1px solid #D7D8E7;
}

._title_b9gr7_12 {

}

._show-script-holder_b9gr7_24 {
    display: flex;
}

._show-script-label_b9gr7_28 {
    cursor: pointer;
    font-size: 11px;
    display: flex;
    padding-left: 8px;
    align-items: center;
}

._show-script-checkbox_b9gr7_36 {

}

._remove-holder_b9gr7_40 {
    display: flex;
    flex-grow: 1;
    justify-content: flex-end;
}

._script-input_b9gr7_46 {
    flex-grow: 1;
    flex-shrink: 0;
}

._high-level-concept-panel_b9gr7_51 {
    display: flex;
    flex-grow: 1;
    flex-shrink: 0;
    border-bottom: 1px solid #D7D8E7;
    background-color: #FFFFFF;
}

._script-address-holder_b9gr7_59 {
    display: flex;
    padding: 8px;
    background-color: #FFFFFF;
    border-bottom: 1px solid #D7D8E7;
}

._remove-button_b9gr7_66 {
    flex-grow: 0;
    flex-shrink: 1;
}`;

(function() {
  if (typeof document === 'undefined') {
    return;
  }
  if (!document.getElementById(digest)) {
    var el = document.createElement('style');
    el.id = digest;
    el.textContent = css;
    document.head.appendChild(el);
  }
})();
    
export default {"scriptEntry":"_script-entry_b9gr7_1","titleRow":"_title-row_b9gr7_12","title":"_title_b9gr7_12","showScriptHolder":"_show-script-holder_b9gr7_24","showScriptLabel":"_show-script-label_b9gr7_28","showScriptCheckbox":"_show-script-checkbox_b9gr7_36","removeHolder":"_remove-holder_b9gr7_40","scriptInput":"_script-input_b9gr7_46","highLevelConceptPanel":"_high-level-concept-panel_b9gr7_51","scriptAddressHolder":"_script-address-holder_b9gr7_59","removeButton":"_remove-button_b9gr7_66"};
export { css, digest };
  