import { NativeSelect } from "@mui/material";
import React from "react";

import { controllers } from "../../../common/catalogue";

export function ControllerPanel(props: {
  scriptAddress: string;
  index: number;
  onChange: (address: string) => void;
}) {
  const { scriptAddress, index, onChange } = props;

  const currentEntry = controllers.find((entry) => entry.url === scriptAddress);
  if (!currentEntry) {
    return <div>Invalid controller script address.</div>;
  }

  return (
    <>
      <div>Select controller:</div>
      <NativeSelect
        fullWidth={true}
        value={scriptAddress}
        onChange={(event) => {
          onChange(event.target.value);
        }}
      >
        {controllers.map((controller, index) => (
          <option key={controller.url} value={controller.url}>
            {controller.name}
          </option>
        ))}
      </NativeSelect>
    </>
  );
}
