import { Button } from "@mui/material";
import * as React from "react";
import { Link } from "react-router-dom";

import { getDomain } from "../../common/domain";
import styles from "./SpaceListItem.module.css";

const domain = getDomain();

export function SpaceListItem(props: {
  spaceId: string;
  title: string;
  aliasName: string;
}) {
  return (
    <Link to={`/space/${props.spaceId}`}>
      <div className={styles.spaceListItem}>
        <div className={styles.spaceTitle}>{props.title}</div>
        <div className={styles.spaceLink}>
          {props.aliasName}.{domain}
        </div>
        <div className={styles.spaceActions}>
          <Button variant={"contained"}>Edit</Button>
          <a
            target={"_blank"}
            onClick={(e) => e.stopPropagation()}
            href={`${window.location.protocol}//${props.aliasName}.${domain}`}
            rel="noreferrer"
          >
            <Button variant={"outlined"}>Navigate To</Button>
          </a>
        </div>
      </div>
    </Link>
  );
}
