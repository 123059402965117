import React from "react";

export function CrowdServicePanel(props: {
  scriptAddress: string;
  index: number;
  onChange: (address: string) => void;
}) {
  const { scriptAddress, index, onChange } = props;
  return <div>A crowd service instance run on-demand for your domain</div>;
}
