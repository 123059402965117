const devDelayMilliseconds = 250;

function isDev(): boolean {
  return (window as any).DEV === "true";
}

export function devDelay(fn: () => void) {
  if (isDev()) {
    setTimeout(() => {
      fn();
    }, devDelayMilliseconds);
  } else {
    fn();
  }
}
