
const digest = '5f698bc5bc1180aa715fc02f3efacd63566032a92c3b284a3ad162e6596e9d7b';
const css = `._space-list-item_1a4qo_1 {
    border: 1px solid gray;
    padding: 8px;
    margin: 8px;
    cursor: pointer;
}

._space-title_1a4qo_8 {
    color: gray;
    font-weight: bold;
}

._space-link_1a4qo_13 {
    padding: 5px;
    font-weight: bold;
}

._space-actions_1a4qo_18 {
    display: flex;
    justify-content: space-between;
    padding: 5px;
}`;

(function() {
  if (typeof document === 'undefined') {
    return;
  }
  if (!document.getElementById(digest)) {
    var el = document.createElement('style');
    el.id = digest;
    el.textContent = css;
    document.head.appendChild(el);
  }
})();
    
export default {"spaceListItem":"_space-list-item_1a4qo_1","spaceTitle":"_space-title_1a4qo_8","spaceLink":"_space-link_1a4qo_13","spaceActions":"_space-actions_1a4qo_18"};
export { css, digest };
  