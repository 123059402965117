import * as React from "react";

import { ControllerPanel } from "../components/Panels/ControllerPanel/ControllerPanel";
import { CrowdServicePanel } from "../components/Panels/CrowdServicePanel/CrowdServicePanel";
import { MMLPanel } from "../components/Panels/MMLPanel/MMLPanel";
import { TerrainPanel } from "../components/Panels/TerrainPanel/TerrainPanel";

export type CatalogueEntry = {
  name: string;
  description: string;
  url: string;
  singleInstance: boolean;
  customPanel?: React.JSXElementConstructor<{
    scriptAddress: string;
    index: number;
    onChange: (address: string) => void;
  }>;
};

export const catalogue: Array<CatalogueEntry> = [
  {
    name: "Script",
    description:
      "A manually-specified script address. Use this to include scripts that are not in the catalogue.",
    url: "",
    singleInstance: false,
  },
  {
    name: "MML Document",
    url: "https://dom.metaweb.tools/index.js?url=",
    description:
      "A script that loads an MML document into the space from a provided websocket (wss://) address",
    singleInstance: false,
    customPanel: MMLPanel,
  },
  {
    name: "Crowd Service",
    url: "https://crowd-service.marcusl.workers.dev/crowd-client.js",
    singleInstance: true,
    description: "A prototype crowd implementation (includes bots)",
    customPanel: CrowdServicePanel,
  },
  {
    name: "Location Persistence",
    url: "https://location.metaweb.tools/location.js",
    singleInstance: true,
    description:
      "Keeps the location of the user in the URL to allow sharing of locations in the space and refreshing the page whilst remaining in the same location",
  },
  {
    name: "MetaWeb Launcher",
    url: "https://metaweb.directory/launcher.js",
    singleInstance: true,
    description:
      "The prototype launcher for MetaWeb that allows loading and unloading MetaWeb scripts",
  },
  {
    name: "MetaWeb 3D Space",
    url: "https://metaweb.host/meta-space.js",
    singleInstance: true,
    description:
      "A basic THREE.js-powered 3D space with a camera that can be controlled by other scripts",
  },
  {
    name: "Sunny Day",
    url: "https://sunny-day.metaweb.tools/sunny-day.js",
    singleInstance: true,
    description: "A basic blue sky and clouds skybox with sunlight",
  },
];

type TerrainEntry = {
  name: string;
  url: string;
  description: string;
};
export const terrains: Array<TerrainEntry> = [
  {
    name: "Pirate Environment",
    description: "A pirate-themed environment",
    url: "https://pirate-bay.example.metaweb.host/pirates/scene-bootstrap/scene-bootstrap.js",
  },
  {
    name: "Construct Environment",
    description: "A basic white-tiled floor environment",
    url: "https://construct.metaweb.tools/construct-env.js",
  },
  {
    name: "Polygon Town Environment",
    description: "A basic polygonal town environment",
    url: "https://polygon.example.metaweb.host/polygon-town/scene-bootstrap/scene-bootstrap.js",
  },
];
for (const terrain of terrains) {
  catalogue.push({
    name: terrain.name,
    url: terrain.url,
    description: terrain.description,
    singleInstance: true,
    customPanel: TerrainPanel,
  });
}

type ControllersEntry = {
  name: string;
  url: string;
  description: string;
};
export const controllers: Array<ControllersEntry> = [
  {
    name: "First Person Controller",
    url: "https://avatar.metaweb.tools/basic-avatar-controller.js",
    description:
      "Allows you to move around with WASD and pointer capture in first person",
  },
  {
    name: "Third Person Controller",
    url: "https://third-person.metaweb.tools/third-person-controller.js",
    description:
      "Allows you to move around with WASD and pointer capture in third person",
  },
];
for (const controller of controllers) {
  catalogue.push({
    name: controller.name,
    url: controller.url,
    singleInstance: true,
    description: controller.description,
    customPanel: ControllerPanel,
  });
}
