
const digest = '7d70adce49f17236aab75ed8de52630f1609ec333dd7a09e7eaa6db16bdde339';
const css = `._login_iaxjx_1 {

}

._magic-link-sign-in_iaxjx_5 {
    padding: 10px;
    border: 1px solid gray;
}

._create-account_iaxjx_10 {
    padding: 10px;
    border: 1px solid orange;
}`;

(function() {
  if (typeof document === 'undefined') {
    return;
  }
  if (!document.getElementById(digest)) {
    var el = document.createElement('style');
    el.id = digest;
    el.textContent = css;
    document.head.appendChild(el);
  }
})();
    
export default {"login":"_login_iaxjx_1","magicLinkSignIn":"_magic-link-sign-in_iaxjx_5","createAccount":"_create-account_iaxjx_10"};
export { css, digest };
  