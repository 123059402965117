import {
  ErrorWithStatusCode,
  HTTPBodies,
  jsonApiRequest,
} from "@meta-web/configuration-durable-object";
import { Button, TextField } from "@mui/material";
import { SpaceConfig, SpaceConfigContent } from "common";
import * as React from "react";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { setPageTitle } from "../common";
import { getDomain } from "../common/domain";
import { CopyToClipboard } from "../components/CopyToClipboard";
import { ScriptEntry } from "../components/ScriptEntry/ScriptEntry";
import { ScriptsCatalogue } from "../components/ScriptsCatalogue/ScriptsCatalogue";
import { devDelay } from "../dev-delay";
import styles from "./SpacePage.module.css";

export function Space() {
  const { spaceId } = useParams();
  const [loadedAliasName, setLoadedAliasName] = useState("");
  const [aliasName, setAliasName] = useState("");
  const [spaceName, setSpaceName] = useState("");
  const [scriptEntries, setScriptEntries] = useState<Array<string>>([]);

  function getSpaceConfig(): SpaceConfigContent {
    return {
      aliasName,
      title: spaceName,
      addresses: scriptEntries,
    };
  }

  const [loading, setLoading] = useState(true);
  const [loadError, setLoadError] = useState<ErrorWithStatusCode | null>(null);

  const [lastSavedState, setLastSavedState] = useState<string | null>(null);

  const [saving, setSaving] = useState(false);
  const [saveError, setSaveError] = useState<ErrorWithStatusCode | null>(null);

  const [previewEnabled, setPreviewEnabled] = useState(false);

  const iframeRef = React.useRef<HTMLIFrameElement>(null);

  useEffect(() => {
    if (spaceName === "") {
      setPageTitle("Loading...");
    } else {
      setPageTitle(`Editing ${spaceName}`);
    }
  }, [spaceName]);

  useEffect(() => {
    devDelay(() => {
      jsonApiRequest<any>("GET", `/api/v1/spaces/${spaceId}`)
        .then((json) => {
          console.log("GET space", json);
          setLastSavedState(
            JSON.stringify({
              aliasName: json.space.aliasName,
              title: json.space.title,
              addresses: json.space.addresses,
            })
          );
          setSpaceName(json.space.title);
          setAliasName(json.space.aliasName);
          setLoadedAliasName(json.space.aliasName);
          setScriptEntries(json.space.addresses);
          setLoading(false);
        })
        .catch((e) => {
          console.error("loadError", e);
          setLoadError(e);
        });
    });
  }, [spaceId]);

  function save() {
    if (saving) {
      return;
    }
    setSaving(true);
    setSaveError(null);

    const savingAliasName = aliasName;

    const savingConfig = getSpaceConfig();

    devDelay(() => {
      jsonApiRequest<
        HTTPBodies<SpaceConfig>["HTTPUpdateConfigurationResponse"]
      >("PUT", `/api/v1/spaces/${spaceId}`, savingConfig)
        .then(() => {
          setLastSavedState(JSON.stringify(savingConfig));
          setLoadedAliasName(savingAliasName);
          setSaving(false);
          if (iframeRef.current) {
            iframeRef.current.src += "";
          }
        })
        .catch((e) => {
          console.error("saveError", e);
          setSaveError(e);
        })
        .finally(() => {
          setSaving(false);
        });
    });
  }

  const isUnsaved = JSON.stringify(getSpaceConfig()) !== lastSavedState;

  if (loadError) {
    return (
      <>
        <div>Error loading space: {loadError.error}</div>
      </>
    );
  }

  if (loading) {
    return (
      <>
        <div>Loading...</div>
      </>
    );
  }

  const domain = getDomain();
  let spaceAddress = `https://${loadedAliasName}.${domain}`;
  if (domain.indexOf("localhost") !== -1) {
    spaceAddress = `http://${loadedAliasName}.spaceconfigdev.com:${window.location.port}`;
  }

  return (
    <>
      <div>
        <h1>Edit Space - {spaceName}</h1>
        <div className={styles.spacePageHolder}>
          <div className={styles.editArea}>
            <div className={styles.saveForm}>
              {saving && <div className={styles.saveFormSaving}>Saving...</div>}
              <div className={styles.spaceNameHolder}>
                <TextField
                  size="small"
                  label="Space Name"
                  value={spaceName}
                  onChange={(e) => setSpaceName(e.target.value)}
                  error={false}
                  disabled={saving}
                  helperText={""}
                />
              </div>
              <div className={styles.spaceAddressHolder}>
                <TextField
                  size="small"
                  label="Space Address"
                  value={aliasName}
                  onChange={(e) => setAliasName(e.target.value)}
                  error={false}
                  disabled={saving}
                  helperText={""}
                />
                .{getDomain()}
              </div>
              <div className={styles.spaceLinkHolder}>
                <a href={spaceAddress} target={"_blank"} rel={"noreferrer"}>
                  <Button sx={{ textTransform: "none" }}>{spaceAddress}</Button>
                </a>
                <CopyToClipboard>
                  {({ copy }) => (
                    <Button
                      variant={"contained"}
                      size={"small"}
                      onClick={() => copy(spaceAddress)}
                    >
                      Copy
                    </Button>
                  )}
                </CopyToClipboard>
              </div>
              <h4>Scripts</h4>
              {scriptEntries.map((scriptEntry, index) => {
                return (
                  <ScriptEntry
                    index={index}
                    disabled={saving}
                    address={scriptEntry}
                    key={index}
                    onChange={(newValue) => {
                      scriptEntries[index] = newValue;
                      setScriptEntries([...scriptEntries]);
                    }}
                    onRemove={() => {
                      scriptEntries.splice(index, 1);
                      setScriptEntries([...scriptEntries]);
                    }}
                  />
                );
              })}

              <div>
                <Button
                  variant={"contained"}
                  disabled={saving}
                  onClick={() => {
                    save();
                  }}
                >
                  Save
                </Button>
                {saveError && <div>Error saving: {saveError.error}</div>}
              </div>
            </div>

            <ScriptsCatalogue
              scriptEntries={scriptEntries}
              addScript={(scriptUrl) => {
                setScriptEntries([...scriptEntries, scriptUrl]);
              }}
            />
          </div>
          <div
            className={[
              styles.previewSide,
              previewEnabled ? styles.previewEnabled : "",
            ].join(" ")}
          >
            <div className={styles.previewArea}>
              <div className={styles.previewContainer}>
                {previewEnabled && (
                  <iframe
                    ref={iframeRef}
                    className={styles.previewIframe}
                    src={spaceAddress}
                  />
                )}
                {!previewEnabled && (
                  <Button
                    variant={"contained"}
                    onClick={() => setPreviewEnabled(true)}
                  >
                    Enable Preview
                  </Button>
                )}
              </div>
              {previewEnabled && (
                <>
                  <Button
                    variant={"contained"}
                    onClick={() => setPreviewEnabled(false)}
                  >
                    Disable Preview
                  </Button>
                  <a href={spaceAddress} target={"_blank"} rel={"noreferrer"}>
                    <Button>Open in new tab</Button>
                  </a>
                </>
              )}
              {isUnsaved && (
                <div className={styles.unsavedWarning}>
                  You have unsaved changes
                  <Button variant={"contained"} onClick={() => save()}>
                    Save
                  </Button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
