
const digest = 'fec75d7799057eb2a44b96fcb40e423638993fd4d06856b76adc816f4f7e3cfa';
const css = `._space-page-holder_7ioqq_1 {
    display: flex;
    flex-direction: row;
    width: 100%;
}

._preview-side_7ioqq_7 {
    flex-basis: 100%;
    flex: 0.5;
}

._preview-area_7ioqq_12 {
    position: sticky;
    top: 80px;
}

._preview-enabled_7ioqq_17 {
    flex: 1;
}

._preview-iframe_7ioqq_21 {
    width: 100%;
    height: 100%;
    border: 1px solid black;
}

._preview-container_7ioqq_27 {
    aspect-ratio: 3 / 2;
    background-color: #697E96;
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
}

._space-name-holder_7ioqq_36 {

}

._space-address-holder_7ioqq_40 {
    margin-top: 10px;
    display: flex;
    align-items: center;
}

._space-link-holder_7ioqq_46 {
    margin-top: 10px;
    display: flex;
    align-items: center;
}

._edit-area_7ioqq_52 {
    flex-basis: 100%;
    flex: 1;
}

._save-form_7ioqq_57 {
    padding: 8px;
    background-color: #F2F7FA;
    border: 1px solid #D7D8E7;
}

._save-form-saving_7ioqq_63 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
}

`;

(function() {
  if (typeof document === 'undefined') {
    return;
  }
  if (!document.getElementById(digest)) {
    var el = document.createElement('style');
    el.id = digest;
    el.textContent = css;
    document.head.appendChild(el);
  }
})();
    
export default {"spacePageHolder":"_space-page-holder_7ioqq_1","previewSide":"_preview-side_7ioqq_7","previewArea":"_preview-area_7ioqq_12","previewEnabled":"_preview-enabled_7ioqq_17","previewIframe":"_preview-iframe_7ioqq_21","previewContainer":"_preview-container_7ioqq_27","spaceNameHolder":"_space-name-holder_7ioqq_36","spaceAddressHolder":"_space-address-holder_7ioqq_40","spaceLinkHolder":"_space-link-holder_7ioqq_46","editArea":"_edit-area_7ioqq_52","saveForm":"_save-form_7ioqq_57","saveFormSaving":"_save-form-saving_7ioqq_63"};
export { css, digest };
  