import { Button } from "@mui/material";
import * as React from "react";

import { catalogue } from "../../common/catalogue";
import styles from "./ScriptsCatalogue.module.css";

export function ScriptsCatalogue(props: {
  scriptEntries: Array<string>;
  addScript: (script: string) => void;
}) {
  return (
    <div>
      <h4>Catalogue</h4>
      {catalogue.map((entry) => {
        let disabledReason = "";
        if (entry.singleInstance && props.scriptEntries.includes(entry.url)) {
          disabledReason =
            "This script is already added and only one instance of it should be present.";
        }
        return (
          <div key={entry.url} className={styles.catalogueEntry}>
            <div className={styles.catalogueEntryName}>{entry.name}</div>
            <div className={styles.catalogueEntryDescription}>
              {entry.description}
            </div>
            <div className={styles.addArea}>
              <Button
                variant="contained"
                disabled={disabledReason !== ""}
                onClick={() => {
                  props.addScript(entry.url);
                }}
              >
                Add
              </Button>
              <div className={styles.catalogueEntryDisabledReason}>
                {disabledReason}
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
}
