import {
  isErrorWithStatusCode,
  jsonApiRequest,
} from "@meta-web/configuration-durable-object";
import { HTTPBodies } from "common";
import { useEffect, useState } from "react";
import * as React from "react";

import { setPageTitle } from "../common";
import { NewSpaceButton } from "../components/NewSpaceButton";
import { SpaceListItem } from "../components/SpaceListItem/SpaceListItem";

export function SpacesPage() {
  useEffect(() => {
    setPageTitle("Spaces");
  }, []);
  const [userSpaces, setUserSpacesJson] = useState<
    HTTPBodies["HTTPGetMySpacesResponse"] | null
  >(null);

  useEffect(() => {
    jsonApiRequest<HTTPBodies["HTTPGetMySpacesResponse"]>(
      "GET",
      `/api/v1/my-spaces`
    )
      .then(setUserSpacesJson)
      .catch((e) => {
        console.error(e);
        if (isErrorWithStatusCode(e)) {
          if (e.statusCode >= 400 && e.statusCode < 500) {
            window.location.reload();
          }
        }
      });
  }, []);

  if (userSpaces === null) {
    return (
      <>
        <div>Loading...</div>
      </>
    );
  }

  console.log("spacesMap", userSpaces.spaces);

  return (
    <>
      <div>
        <h1>Your Spaces</h1>
        <>
          {userSpaces.spaces.map((space) => (
            <SpaceListItem
              key={space.id}
              spaceId={space.id}
              title={space.title}
              aliasName={space.aliasName}
            />
          ))}
        </>
        <>
          <NewSpaceButton />
        </>
      </div>
    </>
  );
}
