import * as React from "react";
import { useEffect } from "react";

import { setPageTitle } from "../common";
import styles from "./LoginPage.module.css";

export function LoginPage() {
  useEffect(() => {
    setPageTitle("Login");
  }, []);

  return (
    <>
      <div className={styles.createAccount}>
        <div>Create an Account or Log In</div>
        <a href={"auth/google/login"}>Log In or Sign Up via Google</a>
      </div>
    </>
  );
}
