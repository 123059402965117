
const digest = '59bc0d1607d4b76d02db3d76a084955b3090f4d5d731849a95380b20bd0c51c5';
const css = `a {
    text-decoration: none;
    color: inherit;
}`;

(function() {
  if (typeof document === 'undefined') {
    return;
  }
  if (!document.getElementById(digest)) {
    var el = document.createElement('style');
    el.id = digest;
    el.textContent = css;
    document.head.appendChild(el);
  }
})();
    
export default {};
export { css, digest };
  