import { Button, TextField } from "@mui/material";
import React from "react";

import { catalogue } from "../../common/catalogue";
import styles from "./ScriptEntry.module.css";

export function ScriptEntry(props: {
  disabled: boolean;
  index: number;
  address: string;
  onChange: (address: string) => void;
  onRemove: () => void;
}) {
  const [showScript, setShowScript] = React.useState(false);

  const higherLevelConceptPanel = GetHigherLevelConceptPanel(
    props.address,
    props.index,
    props.onChange
  );

  let title = "Script";
  if (higherLevelConceptPanel !== null) {
    title = higherLevelConceptPanel[0];
  }

  return (
    <div className={styles.scriptEntry}>
      <div className={styles.titleRow}>
        <div className={styles.title}>{title}</div>
        {higherLevelConceptPanel !== null && (
          <div className={styles.showScriptHolder}>
            <label className={styles.showScriptLabel}>
              <input
                className={styles.showScriptCheckbox}
                type={"checkbox"}
                checked={showScript}
                onChange={() => setShowScript(!showScript)}
              />
              <span>Show Script</span>
            </label>
          </div>
        )}
        <div className={styles.removeHolder}>
          <Button
            className={styles.removeButton}
            disabled={props.disabled}
            onClick={() => {
              props.onRemove();
            }}
          >
            Remove
          </Button>
        </div>
      </div>
      {(!higherLevelConceptPanel || showScript) && (
        <div className={styles.scriptAddressHolder}>
          <TextField
            size="small"
            fullWidth={true}
            label="Script Address"
            value={props.address}
            onChange={(event) => props.onChange(event.target.value)}
            error={false}
            helperText={""}
          />
        </div>
      )}
      {higherLevelConceptPanel && (
        <div className={styles.highLevelConceptPanel}>
          {higherLevelConceptPanel[1]}
        </div>
      )}
    </div>
  );
}

const mmlPrefix = "https://dom.metaweb.tools/index.js";

function GetHigherLevelConceptPanel(
  scriptAddress: string,
  index: number,
  onChange: (address: string) => void
): [string, React.ReactNode | null] | null {
  // if (scriptAddress === "https://metaweb.host/meta-space.js") {
  //   return [
  //     "MetaWeb 3D Space",
  //     index !== 0 ? (
  //       <span>This may cause errors if it is not the first script</span>
  //     ) : null,
  //   ];
  // }

  for (const entry of catalogue) {
    if (entry.url !== "" && scriptAddress.indexOf(entry.url) === 0) {
      return [
        entry.name,
        entry.customPanel ? (
          <entry.customPanel
            key={index}
            scriptAddress={scriptAddress}
            index={index}
            onChange={onChange}
          />
        ) : null,
      ];
    }
  }

  return null;
}
