import { Button, TextField } from "@mui/material";
import React, { useState } from "react";

import styles from "./MMLPanel.module.css";

function PositionInput(props: {
  name: string;
  value: string | null;
  url: URL;
  onChange: (newUrl: string) => void;
}) {
  const { name, value, url, onChange } = props;
  return (
    <div className={styles.positionInput}>
      <TextField
        fullWidth={true}
        size="small"
        type={"text"}
        label={name}
        onChange={(event) => {
          url.searchParams.set(name, event.target.value);
          onChange(url.toString());
        }}
        value={value}
      ></TextField>
    </div>
  );
}

const mmlEditorPrefix = "wss://mmleditor.com/v0/api/documents/";

export function MMLPanel(props: {
  scriptAddress: string;
  index: number;
  onChange: (address: string) => void;
}) {
  const parsedUrl = new URL(props.scriptAddress);
  const mmlUrl = parsedUrl.searchParams.get("url");
  const x = parsedUrl.searchParams.get("x");
  const y = parsedUrl.searchParams.get("y");
  const z = parsedUrl.searchParams.get("z");

  const hasPositionAttribute = x || y || z;

  const [showPosition, setShowPosition] = useState(false);

  function onPositionChange(newUrl: string) {
    if (!showPosition) {
      setShowPosition(true);
    }
    props.onChange(newUrl);
  }

  return (
    <div className={styles.mmlPanel}>
      <div className={styles.mainRow}>
        <TextField
          size="small"
          fullWidth={true}
          placeholder={"wss://example.com/my-mml-document"}
          label="MML Document Address"
          value={mmlUrl}
          onChange={(event) => {
            parsedUrl.searchParams.set("url", event.target.value);
            props.onChange(parsedUrl.toString());
          }}
          error={false}
          helperText={""}
        />
        {!hasPositionAttribute && (
          <Button
            variant={"outlined"}
            onClick={() => {
              setShowPosition(!showPosition);
            }}
          >
            {showPosition ? "Hide Edit Position" : "Edit Position"}
          </Button>
        )}
      </div>
      {!mmlUrl && (
        <a target={"_blank"} rel={"noreferrer"} href={`https://mmleditor.com/`}>
          <Button>Open MML Editor to make a new MML Document</Button>
        </a>
      )}
      {mmlUrl?.indexOf(mmlEditorPrefix) === 0 && (
        <a
          target={"_blank"}
          rel={"noreferrer"}
          href={`https://mmleditor.com/e/${mmlUrl.substring(
            mmlEditorPrefix.length
          )}`}
        >
          <Button>Edit in MML Editor</Button>
        </a>
      )}
      {(hasPositionAttribute || showPosition) && (
        <div className={styles.positionArea}>
          <div className={styles.positionLabel}>Position</div>
          <div className={styles.positionInputs}>
            <PositionInput
              name={"x"}
              value={x}
              url={parsedUrl}
              onChange={onPositionChange}
            />
            <PositionInput
              name={"y"}
              value={y}
              url={parsedUrl}
              onChange={onPositionChange}
            />
            <PositionInput
              name={"z"}
              value={z}
              url={parsedUrl}
              onChange={onPositionChange}
            />
          </div>
        </div>
      )}
    </div>
  );
}
