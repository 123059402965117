
const digest = 'ea7b8ad5d183e0e590eb9a2f2c0d6d3baea01758920cddc704cad04bc55afcf8';
const css = `._catalogue-entry_1q2ia_1 {
    padding: 8px;
    background-color: #F4F8FB;
    margin-bottom: 5px;
    cursor: pointer;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    border-radius: 3px;
    border: 1px solid #D7D8E7;
}

._catalogue-entry-name_1q2ia_11 {
    font-weight: bold;
}

._catalogue-entry-description_1q2ia_15 {
    font-size: 0.8em;
    color: #666;
    padding-top: 8px;
    padding-bottom: 8px;
}

._add-area_1q2ia_22 {
    display: flex;
    align-items: center;
}

._catalogue-entry-disabled-reason_1q2ia_27 {
    font-size: 0.8em;
    color: grey;
    padding: 8px;
}


`;

(function() {
  if (typeof document === 'undefined') {
    return;
  }
  if (!document.getElementById(digest)) {
    var el = document.createElement('style');
    el.id = digest;
    el.textContent = css;
    document.head.appendChild(el);
  }
})();
    
export default {"catalogueEntry":"_catalogue-entry_1q2ia_1","catalogueEntryName":"_catalogue-entry-name_1q2ia_11","catalogueEntryDescription":"_catalogue-entry-description_1q2ia_15","addArea":"_add-area_1q2ia_22","catalogueEntryDisabledReason":"_catalogue-entry-disabled-reason_1q2ia_27"};
export { css, digest };
  