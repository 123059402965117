import * as React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";

import { App } from "./App";
import { baseName } from "./common";
import { LoginPage } from "./pages/LoginPage";
import { Space } from "./pages/SpacePage";
import { SpacesPage } from "./pages/SpacesPage";

const userId: string | null = (window as any).USER;

window.localStorage.setItem("loggedIn", userId ? "true" : "false");
console.log("setItem", window.localStorage.getItem("loggedIn"));

window.addEventListener("DOMContentLoaded", () => {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const container = document.getElementById("root")!;
  const root = createRoot(container);
  root.render(
    <React.StrictMode>
      <BrowserRouter basename={baseName}>
        <Routes>
          {userId ? (
            <Route path="/" element={<App />}>
              <Route path="/login" element={<Navigate to="/" replace />} />
              <Route path="/" element={<SpacesPage />} />
              <Route path="/space/:spaceId" element={<Space />} />
            </Route>
          ) : (
            <>
              <Route path="/login" element={<LoginPage />} />
              <Route path="/*" element={<Navigate to="/login" replace />} />
            </>
          )}
        </Routes>
      </BrowserRouter>
    </React.StrictMode>
  );
});
