import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import Paper from "@mui/material/Paper";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { NavLink, Outlet } from "react-router-dom";

import "./App.module.css";
import { loginRoute } from "./common";
import { getUser } from "./get-user";
import { ProfileArea } from "./ProfileArea";
import rawTheme from "./theme";

const pages = [
  {
    name: "Dashboard",
    path: "/",
  },
];

const mdTheme = createTheme(rawTheme());

export function App() {
  const user = getUser();

  return (
    <>
      <div>
        <ThemeProvider theme={mdTheme}>
          <Box sx={{ display: "flex" }}>
            <CssBaseline />
            <AppBar>
              <Container maxWidth="xl">
                <Toolbar disableGutters>
                  <NavLink to={"/"}>
                    <Typography
                      variant="h6"
                      noWrap
                      sx={{
                        mr: 2,
                        display: "flex",
                        fontWeight: 700,
                        color: "inherit",
                        textDecoration: "none",
                      }}
                    >
                      Space Config
                    </Typography>
                  </NavLink>

                  <Box sx={{ flexGrow: 1, display: "flex" }}>
                    {user &&
                      pages.map((page) => (
                        <NavLink to={page.path} key={page.name}>
                          <Button
                            key={page.name}
                            sx={{ my: 2, color: "white", display: "block" }}
                          >
                            {page.name}
                          </Button>
                        </NavLink>
                      ))}
                  </Box>

                  <Box sx={{ flexGrow: 0 }}>
                    <ProfileArea user={user} />
                  </Box>
                </Toolbar>
              </Container>
            </AppBar>
            <Box
              component="main"
              sx={{
                flexGrow: 1,
                height: "100vh",
                overflow: "auto",
              }}
            >
              <Toolbar />
              <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
                {user ? (
                  <Outlet />
                ) : (
                  <Paper>
                    <Typography variant={"h6"}>Not logged in</Typography>
                    <a href={loginRoute}>
                      <Button variant={"contained"}>Sign in</Button>
                    </a>{" "}
                  </Paper>
                )}
              </Container>
            </Box>
          </Box>
        </ThemeProvider>
      </div>
    </>
  );
}
